import React from 'react';
import styled from 'styled-components';

const OpeningTextHeading = styled.h2`
  padding: ${props => props.padding || '200px 0'};

  & > p {
    font-size: 36px;
    line-height: 1.25;
    text-align: center;

    margin: 0;
  }

  @media (max-width: 767px) {
    padding: 0;

    & > p {
      font-size: 20px;
    }
  }
`;

const OpeningText = props => (
  <OpeningTextHeading
    padding={props.padding}
    dangerouslySetInnerHTML={{
      __html: props.text,
    }}
  />
);

export default OpeningText;
