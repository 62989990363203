import React from 'react';
import { graphql } from 'gatsby';

import { Row, Col } from 'styled-bootstrap-grid';
import ContentContainer from '../components/global/contentContainer';

import Helmet from 'react-helmet';

import OpeningText from '../components/text/openingText';

import styled from 'styled-components';

// export out into it's own page or component
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';
import '../components/styles/accordion.css';

const ContainerTheme = {
  backgroundColor: '#d2f5e6',
};

const FAQWrapper = styled.div`
  background-color: #fff;

  max-width: 1100px;
  margin: 0 auto;
`;

const FAQContainer = styled.div``;

const FAQTitle = styled.div`
  background-color: #00aa90;

  padding: 18px 24px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    & h2 {
      font-size: 15px;
    }
  }
`;

const FAQAccordionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 18px 24px;

  & h3 {
    font-size: 20px;
  }

  @media (max-width: 767px) {
    & h3 {
      font-size: 15px;
    }
  }
`;

const FAQAccordionText = styled.div`
  padding: 40px;

  & p {
    font-size: 20px;

    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  & p:first-of-type {
    margin-top: 0;
  }

  & ul {
    padding: 0;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    & li {
      margin-left: 20px;
    }
  }

  @media (max-width: 767px) {
    padding: 20px;
    & p {
      font-size: 15px;
    }
  }
`;

const FAQ = ({ data, location }) => {
  const faqs = data.prismicFaq.data.body.map((section, index) => {
    let questionAnswers = section.items.map((singleQA, index) => {
      return (
        <AccordionItem key={index}>
          <AccordionItemTitle>
            <FAQAccordionTitle>
              <h3>{singleQA.question.text}</h3>
            </FAQAccordionTitle>
          </AccordionItemTitle>
          <AccordionItemBody>
            <FAQAccordionText
              dangerouslySetInnerHTML={{ __html: singleQA.answer.html }}
            />
          </AccordionItemBody>
        </AccordionItem>
      );
    });
    return (
      <FAQContainer key={index}>
        <FAQTitle>
          <h2>{index + 1 + ' ' + section.primary.section_title.text}</h2>
        </FAQTitle>
        <Accordion>{questionAnswers}</Accordion>
      </FAQContainer>
    );
  });

  return (
    <>
      <Helmet>
        <title>{data.prismicFaq.data.title.text}</title>
        <meta name="title" content={data.prismicFaq.data.title.text} />
        <meta name="description" content={data.prismicFaq.data.text.text} />
        <meta
          property="og:url"
          content={'https://www.draw-brighton.co.uk' + location.pathname}
        />
        <meta
          property="og:description"
          content={data.prismicFaq.data.text.text}
        />
        <meta property="og:locale" content="en" />
        <meta name="twitter:title" content={data.prismicFaq.data.title.text} />
        <meta
          name="twitter:description"
          content={data.prismicFaq.data.text.text}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Helmet title={data.prismicFaq.data.title.text} />
      <Row css={ContainerTheme} justifyContent="center">
        <Col col={12} md={10} lg={9}>
          <ContentContainer padding={'0 36px'}>
            <OpeningText
              padding={'108px 0'}
              text={data.prismicFaq.data.text.html}
              case="inherit"
            />
          </ContentContainer>
        </Col>

        <Col col={12}>
          <ContentContainer padding={'0 36px 54px'}>
            <FAQWrapper>{faqs}</FAQWrapper>
          </ContentContainer>
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  {
    prismicFaq {
      data {
        title {
          text
        }
        text {
          html
          text
        }
        body {
          ... on PrismicFaqBodyFaqSection {
            id
            items {
              question {
                text
              }
              answer {
                html
              }
            }
            primary {
              section_title {
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default FAQ;
